@font-face {
	font-family: 'LDGCIcons';
  	src: url('./LDGC-Regular\ big.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

[class^="ldgcicons-"], [class*="ldgcicons-"] {
	font-family: 'LDGCIcons' !important;
	speak-as: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ldgcicons-spiaggia-sabbia:before {
  content: "\0041";
}

.ldgcicons-spiaggia-ghiaia:before {
	content: "\0042";
  }

.ldgcicons-spiaggia-erba:before {
  content: "\0043";
}

.ldgcicons-spettacoli-serali:before {
	content: "\0044";
}

.ldgcicons-junior-club:before {
	content: "\0045";
}
  
.ldgcicons-area-giochi-indoor:before {
	content: "\0046";
}
 
.ldgcicons-ping-pong:before {
	content: "\0047";
}

.ldgcicons-sport-acquatici:before {
	content: "\0048";
}

.ldgcicons-spray-park:before {
	content: "\0049";
}

.ldgcicons-attracco-natanti:before {
	content: "\004A";
}

.ldgcicons-scivolo-barche:before {
	content: "\004B";
}

.ldgcicons-info-point:before {
	content: "\004C";
}

.ldgcicons-canyoning:before {
	content: "\004D";
}

.ldgcicons-climbing:before {
	content: "\004E";
}

.ldgcicons-nordic-walking:before {
	content: "\004F";
}

.ldgcicons-parapendio:before {
	content: "\0050";
}

.ldgcicons-sport-fiume:before {
	content: "\0051";
}

.ldgcicons-sport-invernali:before {
	content: "\0052";
}

.ldgcicons-trekking:before {
	content: "\0053";
}

.ldgcicons-wakeboard:before {
	content: "\0054";
}

.ldgcicons-cicloturismo:before {
	content: "\0055";
}

.ldgcicons-dolci-formaggi:before {
	content: "\0056";
}

.ldgcicons-olio:before {
	content: "\0057";
}

.ldgcicons-pesce:before {
	content: "\0058";
}

.ldgcicons-prodotti-terra:before {
	content: "\0059";
}

.ldgcicons-prodotti-tradizione:before {
	content: "\005A";
}

.ldgcicons-vini-cantine:before {
	content: "\002E";
}

.ldgcicons-funivie-panoramiche:before {
	content: "\0061";
}

.ldgcicons-parchi-acquatici:before {
	content: "\0062";
}

.ldgcicons-parchi-divertimento:before {
	content: "\0063";
}

.ldgcicons-parchi-naturali:before {
	content: "\0064";
}

.ldgcicons-terme:before {
	content: "\0065";
}

.ldgcicons-itinerari-cicloturistici:before {
	content: "\0066";
}

.ldgcicons-itinerari-bambini:before {
	content: "\0067";
}

.ldgcicons-itinerari-paesi:before {
	content: "\0068";
}

.ldgcicons-itinerari-panoramici:before {
	content: "\0069";
}

.ldgcicons-itinerari-storici-religiosi:before {
	content: "\006A";
}

.ldgcicons-itinerari-trekking:before {
	content: "\006B";
}

.ldgcicons-monte-baldo:before {
	content: "\006C";
}

.ldgcicons-tour-barca:before {
	content: "\006D";
}

.ldgcicons-eventi-festivita:before {
	content: "\006E";
}

.ldgcicons-eventi-enogastronomici:before {
	content: "\006F";
}

.ldgcicons-eventi-folkloristici:before {
	content: "\0070";
}

.ldgcicons-eventi-sportivi:before {
	content: "\0071";
}

.ldgcicons-feste-sagre:before {
	content: "\0072";
}

.ldgcicons-fuochi-artificio:before {
	content: "\0073";
}

.ldgcicons-mercati:before {
	content: "\0074";
}

.ldgcicons-eventi-natalizi:before {
	content: "\0075";
}

.ldgcicons-eventi-storici-culturali:before {
	content: "\0076";
}

.ldgcicons-spettacoli-teatro:before {
	content: "\0077";
}

.ldgcicons-eventi-musicali:before {
	content: "\0078";
}

.ldgcicons-guida-cicloturismo:before {
	content: "\0079";
}

.ldgcicons-guida-family:before {
	content: "\007A";
}

.ldgcicons-guida-paesi:before {
	content: "\002C";
}