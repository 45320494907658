.riga-elemento-laterale-media {
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 8px;
}