body {
  margin: 0;
  padding: 0;
  font-family: 'Maven Pro', sans-serif;
  min-height: 100vh;
  background-color: #F7F7F7;
  overflow-y: scroll !important;
  width: 100%;
}

::-webkit-scrollbar{
  width: 0.9vw;
  background: #f2f2f2;
}
::-webkit-scrollbar-thumb{
  width: 0.9vw;
  background:#c4c4c4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-link-client {
  text-decoration: none;
}