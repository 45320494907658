@font-face {
	font-family: 'ServiceIconsNTC';
	src:url('https://ntc.crweb.it/repository/v1.0/fonts/ServiceIconsNTC.eot?4zkqvt');
	src:url('https://ntc.crweb.it/repository/v1.0/fonts/ServiceIconsNTC.eot?4zkqvt#iefix') format('embedded-opentype'),
		url('https://ntc.crweb.it/repository/v1.0/fonts/ServiceIconsNTC.ttf?4zkqvt') format('truetype'),
		url('https://ntc.crweb.it/repository/v1.0/fonts/ServiceIconsNTC.woff?4zkqvt') format('woff'),
		url('https://ntc.crweb.it/repository/v1.0/fonts/ServiceIconsNTC.svg?4zkqvt#ServiceIconsNTC') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="service-"], [class*=" service-"] {
	font-family: 'ServiceIconsNTC' !important;
	speak-as: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.service-wc_separati:before {
  content: "\e900";
}
.service-cuscini:before {
  content: "\e901";
}
.service-asciugatrice:before {
  content: "\e902";
}
.service-lavastoviglie:before {
  content: "\e903";
}
.service-giardino:before {
  content: "\e904";
}
.service-coperte:before {
  content: "\e905";
}
.service-specchio:before {
  content: "\e906";
}
.service-garage_auto:before {
  content: "\e907";
}
.service-bagno:before {
  content: "\e663";
}
.service-camera_letto:before {
  content: "\e664";
}
.service-cucina:before {
  content: "\e665";
}
.service-divano_letto_doppio:before {
  content: "\e666";
}
.service-divano_letto_singolo:before {
  content: "\e667";
}
.service-letto_castello:before {
  content: "\e668";
}
.service-letto_doppio:before {
  content: "\e669";
}
.service-letto_singolo:before {
  content: "\e66a";
}
.service-soggiorno:before {
  content: "\e66b";
}
.service-windsurf:before {
  content: "\e600";
}
.service-wifi:before {
  content: "\e601";
}
.service-wellness:before {
  content: "\e602";
}
.service-wc_piazzola:before {
  content: "\e603";
}
.service-wc_disabili:before {
  content: "\e604";
}
.service-volley:before {
  content: "\e605";
}
.service-veranda:before {
  content: "\e606";
}
.service-vela:before {
  content: "\e607";
}
.service-tv_satellitare:before {
  content: "\e608";
}
.service-tiro_arco:before {
  content: "\e609";
}
.service-tennis:before {
  content: "\e60a";
}
.service-tabaccheria:before {
  content: "\e60b";
}
.service-strutture_accessibili:before {
  content: "\e60c";
}
.service-stagionali:before {
  content: "\e60d";
}
.service-spiaggia_attrezzata:before {
  content: "\e60e";
}
.service-spiaggia_animali:before {
  content: "\e60f";
}
.service-solarium:before {
  content: "\e610";
}
.service-soccorso:before {
  content: "\e611";
}
.service-servizio_pulizia:before {
  content: "\e612";
}
.service-servizio_navetta:before {
  content: "\e613";
}
.service-servizi_igienici:before {
  content: "\e614";
}
.service-sala_giochi:before {
  content: "\e615";
}
.service-ristorante:before {
  content: "\e616";
}
.service-riscaldamento:before {
  content: "\e617";
}
.service-rafting:before {
  content: "\e618";
}
.service-pizzeria:before {
  content: "\e619";
}
.service-piscina_calda:before {
  content: "\e61a";
}
.service-piscina:before {
  content: "\e61b";
}
.service-pingpong:before {
  content: "\e61c";
}
.service-piazzole_ombreggiate:before {
  content: "\e61d";
}
.service-piazzole:before {
  content: "\e61e";
}
.service-pentolame:before {
  content: "\e61f";
}
.service-parco_giochi:before {
  content: "\e620";
}
.service-parco_acquatico:before {
  content: "\e621";
}
.service-parcheggio:before {
  content: "\e622";
}
.service-pallacanestro:before {
  content: "\e623";
}
.service-palestra:before {
  content: "\e624";
}
.service-noleggio_scooter:before {
  content: "\e625";
}
.service-noleggio_gommoni:before {
  content: "\e626";
}
.service-noleggio_bici:before {
  content: "\e627";
}
.service-minigolf:before {
  content: "\e628";
}
.service-miniclub:before {
  content: "\e629";
}
.service-market:before {
  content: "\e62a";
}
.service-maneggio:before {
  content: "\e62b";
}
.service-luce_piazzola:before {
  content: "\e62c";
}
.service-lodgetent:before {
  content: "\e62d";
}
.service-lavanderia:before {
  content: "\e62e";
}
.service-lavaggio_auto:before {
  content: "\e62f";
}
.service-lavaggio_animali:before {
  content: "\e630";
}
.service-kitesurf:before {
  content: "\e631";
}
.service-internet:before {
  content: "\e632";
}
.service-impianti_scii:before {
  content: "\e633";
}
.service-idromassaggio:before {
  content: "\e634";
}
.service-escursioni:before {
  content: "\e636";
}
.service-edicola:before {
  content: "\e637";
}
.service-docce_calde:before {
  content: "\e638";
}
.service-diving:before {
  content: "\e639";
}
.service-contanti:before {
  content: "\e63a";
}
.service-climbing:before {
  content: "\e63b";
}
.service-chiosco_spiaggia:before {
  content: "\e63c";
}
.service-chalet:before {
  content: "\e63d";
}
.service-centro_fitness:before {
  content: "\e63e";
}
.service-cassaforte:before {
  content: "\e63f";
}
.service-case_mobili:before {
  content: "\e640";
}
.service-carta_credito:before {
  content: "\e641";
}
.service-canoa:before {
  content: "\e642";
}
.service-camper:before {
  content: "\e643";
}
.service-camere:before {
  content: "\e644";
}
.service-calcetto:before {
  content: "\e645";
}
.service-bungalow:before {
  content: "\e646";
}
.service-bocce:before {
  content: "\e647";
}
.service-biancheria_letto:before {
  content: "\e648";
}
.service-biancheria_bagno:before {
  content: "\e649";
}
.service-bazar:before {
  content: "\e64a";
}
.service-barbecue:before {
  content: "\e64b";
}
.service-bar:before {
  content: "\e64c";
}
.service-bancomat:before {
  content: "\e64d";
}
.service-aria_condizionata:before {
  content: "\e64e";
}
.service-area_animali:before {
  content: "\e64f";
}
.service-appartamenti:before {
  content: "\e650";
}
.service-animazione:before {
  content: "\e651";
}
.service-animali_non_ammessi:before {
  content: "\e652";
}
.service-animali_limitazioni:before {
  content: "\e653";
}
.service-animali_ammessi:before {
  content: "\e654";
}
.service-angolo_cottura:before {
  content: "\e655";
}
.service-accesso_spiaggia:before {
  content: "\e656";
}
.service-animali_campeggio:before {
  content: "\e800";
}
.service-divertimento:before {
  content: "\e8b6";
}
.service-animali_medi:before {
  content: "\e802";
}
.service-animali_medi-alt:before {
  content: "\e803";
}
.service-animali_piccoli:before {
  content: "\e804";
}
.service-animali_piccoli-alt:before {
  content: "\e805";
}
.service-animali_grandi:before {
  content: "\e806";
}
.service-animali_grandi-alt:before {
  content: "\e807";
}
.service-fasciatoio:before {
  content: "\e808";
}
.service-armadio:before {
  content: "\e809";
}
.service-tostapane:before {
  content: "\e80a";
}
.service-tostapane-alt:before {
  content: "\e80b";
}
.service-telefono:before {
  content: "\e80c";
}
.service-tavolo_pranzo:before {
  content: "\e80d";
}
.service-stendi_biancheria:before {
  content: "\e80e";
}
.service-seggiolone:before {
  content: "\e80f";
}
.service-prodotti_pulizia:before {
  content: "\e810";
}
.service-prodotti_bagno:before {
  content: "\e811";
}
.service-predisposizione_tv:before {
  content: "\e812";
}
.service-predisposizione_tv-alt:before {
  content: "\e813";
}
.service-zanzariera:before {
  content: "\e814";
}
.service-divano:before {
  content: "\e815";
}
.service-divano-alt:before {
  content: "\e816";
}
.service-decoder-satellitare:before {
  content: "\e817";
}
.service-culla:before {
  content: "\e818";
}
.service-cassetta_sicurezza:before {
  content: "\e819";
}
.service-cassetta_sicurezza-alt:before {
  content: "\e81a";
}
.service-canali_satellitari:before {
  content: "\e81b";
}
.service-pay_per_view:before {
  content: "\e81c";
}
.service-caffettiera:before {
  content: "\e81d";
}
.service-caffe_americano:before {
  content: "\e81e";
}
.service-baby_kit:before {
  content: "\e81f";
}
.service-asse_stiro:before {
  content: "\e820";
}
.service-asciugacapelli:before {
  content: "\e821";
}
.service-arredamento_esterno:before {
  content: "\e822";
}
.service-piano_cottura:before {
  content: "\e823";
}
.service-d27:before {
  content: "\e824";
}
.service-caffe_cialde:before {
  content: "\e825";
}
.service-lettino_bambini:before {
  content: "\e826";
}
.service-frigorifero_freezer:before {
  content: "\e827";
}
.service-frigorifero:before {
  content: "\e828";
}
.service-microonde:before {
  content: "\e829";
}
.service-forno:before {
  content: "\e82a";
}
.service-ventilatore:before {
  content: "\e82b";
}
.service-pagamento_bonifico:before {
  content: "\e82c";
}
.service-pagamento_assegni:before {
  content: "\e82d";
}
.service-fotocopiatrice_fax:before {
  content: "\e82e";
}
.service-deposito_sci-alt:before {
  content: "\e82f";
}
.service-deposito_sci:before {
  content: "\e830";
}
.service-custodia_valori:before {
  content: "\e831";
}
.service-aperto_anno:before {
  content: "\e832";
}
.service-aperto_anno-alt:before {
  content: "\e833";
}
.service-videogiochi:before {
  content: "\e834";
}
.service-stampa_internazionale:before {
  content: "\e835";
}
.service-reception_multilingua:before {
  content: "\e836";
}
.service-reception_24:before {
  content: "\e837";
}
.service-zona_pranzo:before {
  content: "\e838";
}
.service-zona_pranzo_esterna:before {
  content: "\e839";
}
.service-soggiorno2:before {
  content: "\e83a";
}
.service-soggiorno-alt:before {
  content: "\e83b";
}
.service-accesso_piste_sci:before {
  content: "\e83c";
}
.service-anfiteatro:before {
  content: "\e83d";
}
.service-ascensore:before {
  content: "\e83e";
}
.service-baby_club-alt:before {
  content: "\e83f";
}
.service-baby_club:before {
  content: "\e840";
}
.service-balcone:before {
  content: "\e841";
}
.service-biblioteca:before {
  content: "\e842";
}
.service-biblioteca-alt:before {
  content: "\e843";
}
.service-bidet:before {
  content: "\e844";
}
.service-camino:before {
  content: "\e845";
}
.service-cappella:before {
  content: "\e846";
}
.service-cucina2:before {
  content: "\e847";
}
.service-cucina_comune:before {
  content: "\e848";
}
.service-deposito_bagagli:before {
  content: "\e849";
}
.service-discoteca:before {
  content: "\e84a";
}
.service-doccia:before {
  content: "\e84b";
}
.service-fattoria:before {
  content: "\e84c";
}
.service-ingresso_indipendente:before {
  content: "\e84d";
}
.service-karaoke:before {
  content: "\e84e";
}
.service-lavanderia_gettoni:before {
  content: "\e84f";
}
.service-lavanderia_gettoni-alt:before {
  content: "\e850";
}
.service-luna_park:before {
  content: "\e851";
}
.service-musica_live:before {
  content: "\e852";
}
.service-negozi:before {
  content: "\e853";
}
.service-parco_avventura:before {
  content: "\e854";
}
.service-parco_avventura-alt:before {
  content: "\e855";
}
.service-parrucchiere:before {
  content: "\e856";
}
.service-parrucchiere-alt:before {
  content: "\e857";
}
.service-piano_bar:before {
  content: "\e858";
}
.service-allaccio_acqua:before {
  content: "\e859";
}
.service-allaccio_acqua-alt:before {
  content: "\e85a";
}
.service-animali_villaggio:before {
  content: "\e801";
}
.service-piscina_idromassaggio:before {
  content: "\e85c";
}
.service-piscina_bambini:before {
  content: "\e85d";
}
.service-piscina_coperta:before {
  content: "\e85e";
}
.service-wc_chimico:before {
  content: "\e85f";
}
.service-wc_comune-alt:before {
  content: "\e860";
}
.service-wc_comune:before {
  content: "\e861";
}
.service-wc_comune-alt2:before {
  content: "\e862";
}
.service-wc_comune-alt3:before {
  content: "\e863";
}
.service-wc_fasciatoi:before {
  content: "\e864";
}
.service-wc_fasciatoi-alt:before {
  content: "\e865";
}
.service-wc_fasciatoi-alt2:before {
  content: "\e866";
}
.service-sistemazione_disabili:before {
  content: "\e867";
}
.service-sistemazione_fumatori:before {
  content: "\e868";
}
.service-sistemazione_non_fumatori:before {
  content: "\e869";
}
.service-spiaggia_scogli:before {
  content: "\e86a";
}
.service-spiaggia_libera:before {
  content: "\e86b";
}
.service-spiaggia_nudista:before {
  content: "\e86c";
}
.service-spiaggia_privata:before {
  content: "\e86d";
}
.service-spiaggia_sabbia:before {
  content: "\e86e";
}
.service-telefono_pubblico:before {
  content: "\e86f";
}
.service-tessera_club:before {
  content: "\e870";
}
.service-terrazzo:before {
  content: "\e871";
}
.service-tornei:before {
  content: "\e872";
}
.service-vasca:before {
  content: "\e873";
}
.service-vasca-alt:before {
  content: "\e874";
}
.service-vasca-alt2:before {
  content: "\e875";
}
.service-vista_citta:before {
  content: "\e876";
}
.service-vista_fiume:before {
  content: "\e877";
}
.service-vista_giardino:before {
  content: "\e878";
}
.service-vista_lago:before {
  content: "\e879";
}
.service-vista_mare:before {
  content: "\e87a";
}
.service-vista_montagna:before {
  content: "\e87b";
}
.service-vista_piscina:before {
  content: "\e87c";
}
.service-zona_pesca:before {
  content: "\e87d";
}
.service-lettini_piscina-alt:before {
  content: "\e87e";
}
.service-lettini_piscina:before {
  content: "\e87f";
}
.service-lettini_spiaggia:before {
  content: "\e880";
}
.service-lettini_spiaggia-alt:before {
  content: "\e881";
}
.service-boa:before {
  content: "\e882";
}
.service-shuttle:before {
  content: "\e883";
}
.service-noleggio_tv:before {
  content: "\e884";
}
.service-noleggio_scooter2:before {
  content: "\e885";
}
.service-noleggio_pedalo:before {
  content: "\e886";
}
.service-noleggio_imbarcazioni:before {
  content: "\e887";
}
.service-noleggio_frigo:before {
  content: "\e888";
}
.service-noleggio_auto:before {
  content: "\e889";
}
.service-terreno_pianura:before {
  content: "\e88a";
}
.service-terreno_collina:before {
  content: "\e88b";
}
.service-montagna:before {
  content: "\e88c";
}
.service-mare:before {
  content: "\e88d";
}
.service-lago:before {
  content: "\e88e";
}
.service-entroterra:before {
  content: "\e88f";
}
.service-collina:before {
  content: "\e890";
}
.service-citta-arte:before {
  content: "\e891";
}
.service-tavola_calda-alt:before {
  content: "\e892";
}
.service-tavola_calda:before {
  content: "\e893";
}
.service-self_service:before {
  content: "\e894";
}
.service-colazione_buffet:before {
  content: "\e895";
}
.service-pranzo_sacco:before {
  content: "\e896";
}
.service-pranzo_sacco-alt:before {
  content: "\e897";
}
.service-pranzo_sacco-alt2:before {
  content: "\e898";
}
.service-pizza_asporto:before {
  content: "\e899";
}
.service-pernottamento_colazione:before {
  content: "\e89a";
}
.service-celiaci:before {
  content: "\e89b";
}
.service-celiaci-alt:before {
  content: "\e89c";
}
.service-r12:before {
  content: "\e89d";
}
.service-pensione_completa:before {
  content: "\e89e";
}
.service-mezza_pensione:before {
  content: "\e89f";
}
.service-bar_spiaggia:before {
  content: "\e8a0";
}
.service-pallavolo:before {
  content: "\e8a1";
}
.service-freccette:before {
  content: "\e8a2";
}
.service-corsi_nuoto:before {
  content: "\e8a3";
}
.service-corsi_equitazione:before {
  content: "\e8a4";
}
.service-centro_surf:before {
  content: "\e8a5";
}
.service-calcio:before {
  content: "\e8a6";
}
.service-biliardo:before {
  content: "\e8a7";
}
.service-pet_friend:before {
  content: "\e8a8";
}
.service-vacanze_culturali-alt:before {
  content: "\e8a9";
}
.service-vacanze_culturali:before {
  content: "\e8aa";
}
.service-comfort:before {
  content: "\e8ab";
}
.service-per_sportivi:before {
  content: "\e8ac";
}
.service-per_risparmiare:before {
  content: "\e8ad";
}
.service-per_risparmiare-alt:before {
  content: "\e8ae";
}
.service-per_relax:before {
  content: "\e8af";
}
.service-per_naturisti:before {
  content: "\e8b0";
}
.service-lui_lei:before {
  content: "\e8b1";
}
.service-natura:before {
  content: "\e8b2";
}
.service-gruppi:before {
  content: "\e8b3";
}
.service-giovani:before {
  content: "\e8b4";
}
.service-famiglie:before {
  content: "\e8b5";
}
.service-ombra_artificiale:before {
  content: "\e85b";
}
.service-stoviglie:before {
  content: "\e658";
}
.service-ferro_da_stiro:before {
  content: "\e659";
}
.service-parcheggio_sistemazione:before {
  content: "\e657";
}
.service-scarico_piazzola:before {
  content: "\e662";
}
.service-wc_acqua_calda:before {
  content: "\e661";
}
.service-golf:before {
  content: "\e660";
}
.service-wc_privati:before {
  content: "\e65f";
}
.service-spiaggia_ghiaia:before {
  content: "\e65e";
}
.service-calcio_balilla:before {
  content: "\e65d";
}
.service-corsi_sci:before {
  content: "\e65c";
}
.service-corsi_sub:before {
  content: "\e65b";
}
.service-pattinaggio:before {
  content: "\e65a";
}
.service-navetta:before {
  content: "\e635";
}