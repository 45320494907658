.header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 8vh;
    border-bottom: 0.5px solid #dcdcdc;
    background-color: white;
}

.header-zona {
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-img-logo {
    width: 80%;
    height: 80%;
    margin-left: 20%;
}

.header-bottoni {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 30px;
    padding-right: 30px;
}

.header-img-utente {
    width: 30px;
    height: 30px;
}