.admin-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #F6F9FC;
    min-height: 87vh;
    align-items: center;
    padding-bottom: 30px;
}

.admin-corpo-modifica {
    display: flex;
    width: 100%;
    min-height: 87vh;
    flex-direction: row;
}

.admin-corpo-modifica-sx {
    display: flex;
    width: 28%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.admin-corpo-modifica-dx {
    display: flex;
    width: 72%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.admin-container {
    width: 70%;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.admin-container-lungo {
    width: 94%;
    margin-right: 2%;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.admin-container-cms {
    width: 96%;
    margin-right: 2%;
    margin-left: 2%;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.admin-container-prima-riga {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.admin-container-seconda-riga {
    width: 100%;
    display: flex;
    align-items: center;
}

.admin-titoletto {
    margin: 0
}

.admin-titoletto-cms {
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal;
}

.admin-titoletto-space {
    margin: 0;
    padding-bottom: 10px;
}

.admin-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-bandiera {
    width: 30px;
    height: 30px;
}

.admin-bandiera-cms {
    width: 24px;
    height: 24px;
}

.admin-login-container {
    background-color: #F6F9FC;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.admin-login-sx {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-login-dx {
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin-login-dx-container {
    width: 70%;
}

.admin-login-img {
    width: 90%;
    height: 250px;
}

.admin-login-bottone {
    width: 100% !important;
}

.admin-login-dx-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin-testo-date {
    font-weight: normal;
}

.admin-tabella {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    margin-top: 20px;
}

.admin-anteprima-img {
    width: 350px;
    height: 200px;
}

.admin-immagine-in-lista {
    opacity: 1;
	-webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    backface-visibility: hidden;
}
.admin-immagine-in-lista:hover {
	opacity: .7;
}

.admin-linea-bottone-immagine {
    max-width: 110px;
}

.admin-lista-immagini-drag {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 120px;
    margin-top: 20px;
    justify-content: flex-start;
}

.admin-skill-immagine-container {
    position: relative;
    display: flex;
    width: 185px;
    height: 100%;
    margin-right: 15px;
}
  
.admin-skill-immagine-image {
    display: block;
    width: 100%;
    height: 100%;
}
  
.admin-skill-immagine-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color:#F9B433;
}
  
.admin-skill-immagine-container:hover .admin-skill-immagine-overlay {
    opacity: 0.7;
}
  
.admin-skill-immagine-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
}

.admin-bottone-skill-immagine {
    top: 8px;
}

.admin-container-lista-allegati {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

.admin-riga-allegato {
    width: 99.8%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    border-bottom: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
}

.admin-riga-allegato-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    margin-top: 10px;
    background-color: #F9B433;
    opacity: 0.9;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.admin-anteprima-allegato {
    width: 60px;
    height: 60px;
}

.admin-link-bottone-download {
    color: white;
    text-decoration: none;
}

.admin-allegato-elemento-1 {
    width: 32%;
}

.admin-allegato-elemento-2 {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-allegato-elemento-1-header {
    width: 32%;
    font-weight: 600;
}

.admin-allegato-elemento-3-header {
    width: 32%;
    font-weight: 600;
    padding-left: 5px;
}

.admin-allegato-elemento-2-header {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.admin-date-header-bold {
    font-size: 13px;
    font-weight: bold;
}

.admin-date-header {
    font-size: 13px;
    font-weight: normal;
}

.admin-anteprima-logo {
    width: 150px;
    height: 150px;
}

.admin-anteprima-pdf {
    width: 100px;
    height: 100px;
}

.admin-anteprima-icona {
    width: 50px;
    height: 50px;
}

.immagine-cms {
    min-width: 300px;
    max-width: 500px;
    height: auto;
}

.immagine-icona-cms {
    width: 68px;
    height: 68px;
}
 