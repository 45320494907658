.container-laterale {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.prima-riga-container-laterale {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.elemento-laterale {
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
    cursor: pointer;
}

.riga-elemento-laterale {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.nome-elemento-laterale {
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.descrizione-elemento-laterale {
    color: #a9a9a9;
    padding-left: 3px;
    font-size: 13px;
}

.cerchio-verde-admin {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: green;
}

.cerchio-rosso-admin {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: red;
}